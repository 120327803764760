import React from 'react';
// import Fragment from 'react'
import {VisualBlock} from "./VisualBlock"
import ReactMarkdown from 'react-markdown';


export function ContentBlock(props) {
  const heading = props.heading
    ? <h4>{props.heading}</h4>
    : null;
  const visualsBlock = props.visuals
    ? (props.visuals.map(visual => (<VisualBlock key={visual.title || visual.caption} {...visual} className="contentVisual"/>)))
    : null;
  const singleVisual = props.visual
    ? <VisualBlock key={props.heading + "visual"} {...props.visual} className="contentVisual"/>
    : null;
  const style = props.visuals
    ? {
      gridRow: "span " + props.visuals.length
    }
    : null;
  return (<>
    <section key={props.heading || props.noHeadingKey} style={style} className={"contentSection" + (
        props.visual || props.visuals
        ? ""
        : " fullRow")}>
      {heading}
      {
        props.type === "md"
          ? <ReactMarkdown children={props.text}/>
          : <p>{props.text}</p>
      }

    </section>
    {singleVisual}
    {visualsBlock}
  </>);
}
